<template>
    <div style="margin:10px 10px 0 10px;background-color: #EFF1F4;height: 100vh">
        <!--搜索-->
        <div class="el-card is-always-shadow" style="padding:20px 0 0 20px;margin-top: 10px;border-radius: 0;">
            <el-form ref="searchForm" :model="searchForm" size="mini" inline style="display: flex">
                <el-form-item label="社区" prop="name" v-if="hrole<11">
                    <el-select v-model="searchForm.hospital" filterable placeholder="请选择" clearable>
                        <el-option
                                v-for="item in HospitalAllData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="name">
                    <el-input v-model="searchForm.phone" clearable/>
                </el-form-item>
                <el-form-item label="添加时间" prop="name">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="searchForm.create_at" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-button type="primary" style="height: 30px" size="mini" @click="onSearch" icon="el-icon-search">查询
                </el-button>
                <el-button type="primary" style="height: 30px" size="mini" @click="addUser" icon="el-icon-circle-plus">
                    新增
                </el-button>
                <el-upload
                        class="upload-demo"
                        :data="params"
                        :show-file-list="false"
                        :on-success="uploadSuccess"
                        :before-upload="uploadBefore"
                        action="/api/hospital/News/userImport">
                    <el-button type="primary" style="height: 30px;margin-left: 10px" size="mini" @click="addUserImport"
                               icon="el-icon-circle-plus">批量导入
                    </el-button>
                </el-upload>
            </el-form>
        </div>
        <div style="margin-top: 10px;height:calc(100vh - 165px);background-color: white;">
            <!--数据-->
            <div style="padding: 10px">
                <el-table v-loading="loading" :row-style="{height:0+'px'}" :cell-style="{padding:5+'px'}"
                          :data="tableData" border
                          style="width: 100%;" header-cell-style="background-color: #f5f5f5; padding:15px 0;">
                    <el-table-column prop="id" label="序号" width="66" align="center"></el-table-column>
                    <el-table-column v-if="hrole<11" prop="hospital_name" label="社区" align="center"
                                     min-width="150"></el-table-column>
                    <el-table-column prop="name" label="用户名ID" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.id:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="用户名" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.name:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="mobile" label="手机号" align="center" width="130"></el-table-column>
                    <el-table-column prop="role_name" label="性别" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.sex==1?'男':'女':'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="role_name" label="出生日期" width="110" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.birth:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="role_name" label="身高/cm" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.height:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="role_name" label="体重/kg" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.weight:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="info" label="病史" align="center" min-width="198">
                        <template slot-scope="scope">
                            <div v-if="scope.row.info.length<10">
                                <span v-if="scope.row.info">
                                     {{scope.row.info}}
                                </span>
                                <span v-else>/</span>
                            </div>
                            <el-popover v-else placement="top-start" width="500" trigger="hover"
                                        :content="scope.row.info">
                                <el-button v-if="scope.row.info" style="border: none;width: 98%" slot="reference">
                                    {{scope.row.info.substring(0, 10)}}
                                </el-button>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="info" label="性格测试" align="center" width="268">
                        <template slot-scope="scope">
                            <div v-if="scope.row.exam_number>0">
                                <span v-for="item in scope.row.exam">{{item.answer=='A'?"H":item.answer=='B'?'L':item.answer=='C'?"W":'P'}}{{item.number}}， </span>
                            </div>
                            <div v-else>
                                /
                            </div>
                        </template>
                        d
                    </el-table-column>
                    <el-table-column prop="create_at" width="178" label="添加时间" align="center"></el-table-column>
                    <el-table-column prop="date" label="操作" width="280" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="userBig(scope.row)">健康管理</el-button>
                            <el-button type="text" size="small" @click="userDevice(scope.row)">设备</el-button>
                            <el-button type="text" size="small" @click="userData(scope.row)">数据</el-button>
                            <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button>
                            <el-button @click="delUser(scope.row)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--分页-->
            <div style="margin-top: 25px;text-align: center">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="page"
                               :page-sizes="[10, 20, 50]" :page-size="pagesize"
                               :total="total" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
        <!--用户信息编辑-->
        <el-dialog title="编辑" :visible.sync="userDialog" width="50%">
            <el-form ref="form" :model="user_form" label-width="80px" input-width="50px">
                <el-form-item label="选择社区" v-if="hrole<11">
                    <el-select v-model="user_form.hospital" placeholder="请选择" clearable style="width: 60%">
                        <el-option v-for="item in HospitalAllData" :key="item.id"
                                   :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input require v-model="user_form.mobile" minlength="3" style="width: 60%"></el-input>
                </el-form-item>
                <el-form-item label="病史">
                    <el-input type="textarea"
                              :rows="6" v-model="user_form.info" minlength="3" style="width: 60%"></el-input>
                </el-form-item>

                <el-form-item>
                    <div style="text-align: right;margin-right: 20px">
                        <el-button @click="userDialog = false">取 消</el-button>
                        <el-button type="primary" @click="updateUser">确 定</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>

</template>
<script>
    import request from "../../plugins/axios";

    export default {
        data() {
            return {
                loading: false,
                token: '123',
                hrole: '',
                selectKey: [],

                //用户管理
                searchForm: {
                    hospital: '',
                    phone: '',
                    create_at: ''
                },
                HospitalAllData: [],//所有社区
                RoleAllData: [],//所有角色
                user_form: {
                    curd: '',
                    mobile: '',
                    info: '',
                    id: '',
                },
                userDialog: false,
                page: 1,
                total: 0,
                pagesize: 10,
                tableData: [],


            };
        },
        components: {},
        mounted() {
            let that = this
            that.params = {
                token: localStorage.getItem('token')
            };
            that.getUserData();
            that.getHospitalAllData();
            this.hrole = localStorage.getItem('hrole')
        },
        methods: {
            uploadBefore() {
                let that = this
                that.loading = true
            },
            uploadSuccess(ret) {
                let that = this
                that.loading = false
                that.$notify({
                    title: '提示',
                    message: ret.data.msg,
                    type: 'success'
                });
            },
            set_uid(e) {
                this.uid = e;
                localStorage.setItem('userInfo', e.id)
                window.open('/admin/indexUser')
            },
            //用户大屏
            userBig(row) {
                console.log(row)
                localStorage.setItem('userInfo', row.user.id)
                window.open(this.$router.resolve({path: "/admin/indexUser"}).href, "_blank");
                // window.open(this.$router.resolve({path: "/admin/userData"}).href, "_blank");
            },
            //用户设备
            userDevice(row) {
                localStorage.setItem('usphone', row.mobile)
                window.open(this.$router.resolve({path: "/admin/devices?uid=666"}).href, "_blank");
            },
            //用户数据
            userData(row) {
                localStorage.setItem('usphone', row.mobile)
                window.open(this.$router.resolve({path: "/admin/data?uid=666"}).href, "_blank");
                //   this.$router.push(link);
            },
            //用户开始
            getUserData(page) {
                let that = this
                let data = that.searchForm
                data.pagesize = that.pagesize
                data.page = page ? page : 1;
                data.hp = 1000
                that.loading = true
                request({
                    url: "/hospital/News/users_lists",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.tableData = ret.data.list
                        that.total = ret.data.total
                    } else if (ret.code == 0) {
                        location.href = "/"
                    }
                    that.loading = false
                });
            },
            //获取所有社区
            getHospitalAllData() {
                let that = this
                request({
                    url: "/hospital/News/hospital_list",
                    data: {pageSize: 1000, page: 1, hp: 1000},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.HospitalAllData = ret.data.list
                    }
                });
            },
            //获取所有角色
            getRoleAllData() {
                let that = this
                request({
                    url: "/hospital/News/role_list",
                    data: {},
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.RoleAllData = ret.data;
                    }
                });
            },
            //用户查询
            onSearch() {
                let that = this.getUserData();
            },
            //导入新增
            addUserImport() {

            },
            //新增
            addUser() {
                let that = this
                that.user_form.curd = 'c';
                that.user_form.mobile = '';
                that.user_form.info = '';

                that.userDialog = true;


            },
            editUser(row) {
                let that = this

                that.user_form.mobile = row.mobile
                that.user_form.info = row.info
                that.user_form.id = row.id;
                that.user_form.curd = 'u';
                that.userDialog = true;

            },
            updateUser() {
                let that = this
                let data = that.user_form

                if (data.curd == 'c') {
                    if (data.mobile.length < 11) {
                        that.$notify({
                            title: '提示',
                            message: "请输入正确的手机号",
                            type: 'warning'
                        });
                        return false;
                    }
                }


                request({
                    url: "/hospital/News/users_update",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {

                        that.$notify({
                            title: '提示',
                            message: ret.msg,
                            type: 'success'
                        });
                        that.getUserData();
                        that.userDialog = false;
                    } else {
                        that.$notify({
                            title: '警告',
                            message: ret.msg,
                            type: 'warning'
                        });
                    }
                });

            },
            //删除用户
            delUser(row) {
                let that = this

                that.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    request({
                        url: "/hospital/News/users_update",
                        data: {curd: 'd', id: row.id},
                    }).then((ret) => {
                        if (ret.code == 1) {

                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'success'
                            });
                            that.getUserData();
                            that.roleDialog = false;
                        } else {
                            that.$notify({
                                title: '提示',
                                message: ret.msg,
                                type: 'warning'
                            });
                        }
                    });
                }).catch(() => {
                    that.$notify({
                        title: '提示',
                        message: "取消删除",
                        type: 'warning'
                    });
                });


            },
            handleSizeChange(pagesize) {
                this.pagesize = pagesize;
                this.getUserData(1);
            },
            handleCurrentChange(page) {
                this.getUserData(page);
            }
        },
    };
</script>